@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "./Scss/_variables.scss";
@import "./Scss/_mixins.scss";
//@import '~video-react/styles/scss/video-react';

body {
  user-select: none;
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
  font-weight: 400;
}

.video-react .video-react-poster {
  background-size: cover;
}

a,
button,
a:active,
button:active,
a:focus,
button:focus {
  outline: none !important;
}

.react-multiple-carousel__arrow--left {
  left: 0%;
}

.react-multiple-carousel__arrow--right {
  right: 0%;
}

a{
  text-decoration: none;
  color:#000;
}

// .container {
//   padding: 0 15px;
// }

// @media (min-width: 575.98px) {
//   .container {
//     max-width: 540px;
//     margin: auto;
//     padding: 0;
//   }
// }

// @media (min-width: 767.98px) {
//   .container {
//     max-width: 720px;
//   }
// }

// @media (min-width: 991.98px) {
//   .container {
//     max-width: 960px;
//   }
// }

// @media (min-width: 1199.98px) {
//   .container {
//     max-width: 1140px;
//   }
// }

// @media (min-width: 1500.98px) {
//   .container {
//     max-width: 1340px !important;
//   }
// }

.test-page {
  height: 400px;
  background: #d7d7d7;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width:768px) {
    height: 30vh;
  }

  h1 {
    font-size: 100px;
    font-weight: 300;
    text-transform: uppercase;

    @media (max-width:768px) {
      font-size: 40px;
      font-weight: 900;
      color: $blue-3;
    }
  }
}

.uppercase {
  text-transform: uppercase;
  margin-bottom: 20px;
}

.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px;
  margin: 100px 0;

  @media (min-width:768px) and (max-width:998px) {
    grid-gap: 50px;
  }

  @media (max-width:768px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  h3 {
    font-size: 28px;
    text-transform: uppercase;
    color: $color-blue;
    font-weight: 500;
    margin-bottom: 20px;
  }
}

#outer-container {
  overflow-x: hidden;
}

// #root {
//   padding-top: 90px;

//   @media (max-width:1100px) {
//     padding-top: 25px;
//   }
// }


.setting-button {
  display: none;
  position: absolute;
  left: -60px;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  border-radius: 30px 0 0 30px;
  background: #0c3146;
  color: #fff;

  @media (max-width:998px) {
      display: flex;
  }

  svg {
      font-size: 30px;
      rect {
          fill: #fff;
      }


  }

  &.active {

      svg {
          animation: none;
      }
  }

  &.unactive {
      svg {
          animation: spin360 8s linear infinite;
      }

  }
}

@keyframes spin360 {
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}