@mixin transition() {
    transition: all 500ms ease;
}

@mixin grid-c($column, $gap) {
    display: grid;
    grid-template-columns: repeat($column, 1fr);
    grid-gap: $gap;
    align-items: center;
}

@mixin grid($column, $gap) {
    display: grid;
    grid-template-columns: repeat($column, 1fr);
    grid-gap: $gap;
}

@mixin link($color, $color-hover, $size) {
    display: block;
    color: $color;
    font-size: $size;
    @include transition;
    &:hover {
        color: $color-hover; 
    }
}

@mixin a($color, $color-hover, $size) {
    display: block;
    color: $color;
    font-size: $size;
    @include transition;
    &:hover {
        color: $color-hover; 
    }
}

@mixin bg-c($url) {
   background: url($url);
   background-size: contain;
   background-position: center;
   background-repeat: no-repeat;
}