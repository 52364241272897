
@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.responsive{
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
   /// width: 100%;
    

}
.right{
    display: flex;
    align-items: center;
    
  
}
.responsive ul li {
    display: inline-block;
    text-transform: uppercase;
    font-size: 18px;
    padding: 0 10px;
}
.searchIcon {
    width: 50px;
    height: 35px;
    background-color: #fff;
    display: grid;
    place-items: center;
}

@media (max-width:1100px){
    .responsive ul{
        position: unset;
        top:70px;
        left:-100%;
        width: 100%;
        text-align: center;
        transition: 0.85s ease-in-out;
        height: 100%;
    }
    .responsive ul li{
        display: block;
        padding: 20px 0;

    }
    .NavBar .Navigation{
        display: none;
    }
    .MobileNavigation{
        display: flex;
    }
    .right{
       //flex:2;
        flex-direction: row-reverse;
        justify-content: center;
    }
    // .form-search{
    // //     display: none;
    // // }
}
#navBar-form input[type="checkbox"]{
    display: none;
}

#navBar-form{
    position: relative;
    display: inline-flex;
    margin-right: 10px;
}
#search-form{
    display: inline-flex;
    height: 40px;
    position:absolute;
    right:100%;
    transform: scale(0,0);
    transform-origin: center right;
    transition: 0.4s transform linear;
}
#search-bar{ 
    width: 290px;
    height: 40px;
    padding: 10px;
    color:$blue-3;
    font-size: 18px;
}
#search-btn{
    width: 45px;
    height:40px;
    display: flex;
    align-items: center;
    font-size: 18px;
    border:none;
    outline:none;
   // background-color: #41414141;
    color:$blue-3;
}

#search-form-opener{
    width: 60px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: bolder;
    color:$blue-3;
}
#search-form-closer{
    width: 60px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: bolder;
    display: none;
  
}
#box-2:checked ~ #search-form{
    transform: scale(1,1);
}

#box-2:checked ~ #search-form-opener{
    display: none;
}

#box-2:checked ~ #search-form-closer{
    display: flex;
}

