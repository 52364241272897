@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

@keyframes vibration {
    0% { transform: translate(0, 0); }
    20% { transform: translate(-1px, 1px); }
    40% { transform: translate(1px, -1px); }
    60% { transform: translate(-1px, 1px); }
    80% { transform: translate(1px, -1px); }
    100% { transform: translate(0, 0); }
}

.displayDevice{
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: white;
    padding: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 300px;
    border: 2px solid $blue-3;
    border-radius: 10px;
    // margin:10px;
    width: 98%;
    // border-radius: 20px;
    margin:10px auto 20px;

    .imgDev{
        flex-basis: 30%; /* La zone de gauche prend 30% de l'espace */
        margin-right: 20px;
        max-height: 260px;
    }
    .imgDev img {
        max-width: auto;
        max-height: 260px;
    }
    .fa-bell{
        color: $blue-3;
        text-align: right;
    }
    .fa-trash-can{

        text-align: right;
    }
    .imgTrash{
        width: 50px; /* La zone de droite a une largeur fixe de 50px */
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .pinfo{
        font-weight: bold;
        text-transform: uppercase;
        font-style: italic;
        width: 100%;
    }
    .content{
        flex-grow: 1; /* La zone centrale prend l'espace restant */
        margin-right: 20px;
    }   
    hr{
       height: 2px;
    }
    .modal {
        display: none;
      }
    .modalAdd{
        display: block;
    }
    .info{
        min-width: 70%;
    }
    .buttonExtended {
        display: inline-flex;
        align-items: center;
        color: rgb(6, 15, 30);
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0px;
        height: 50px;
        min-width: 50%;
        position: relative;
        padding: 0px 20px;
        max-width: fit-content;
        cursor: pointer;
        
        &_text {
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0px;
            z-index: 2;
            text-transform: uppercase;
        }
    
        &:disabled {
            opacity: 0.5;
            background-color: $color-5;
        }
    
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            width: 72px;
            background-color: $color-5;
            border-radius: 40px;
            transition: all 0.3s ease-out 0s;
        }
    
        .hyphen {
            width: 20px;
            height: 2px;
            margin-right: 9px;
            background-color: rgb(6, 15, 30);
            transform-origin: 50% 50%;
            z-index: 1;
        }
    
        &:hover {
            color: rgb(255, 255, 255);
            &_text {
                color: rgb(255, 255, 255);
            }
    
            &:before {
                width: 100%;
            }
    
            .hyphen {
                animation: 1s linear 0s 1 normal none running kMPRhr;
            }
        }
    }
    .ligne-texte-icone {
        display: flex;
        justify-content: space-between; /* Le texte à gauche et l'icône à droite */
        align-items: center; /* Aligne verticalement les éléments au centre */
        padding: 10px;
        background-color: transparent;
        border-radius: 8px;
        width: 100%;
    }
    .squizz {
        animation: vibration 0.3s infinite;
    }
    
    .texte {
        font-size: 18px;
    }
    
    .icone img {
        width: 24px; /* Largeur de l'icône */
        height: auto;
        cursor: pointer;
    }
    .hidden {
        display: none; /* Cache l'icône */
    }
    .color{
        .button_text {
            color: #383838; 
        }
        &:before {
            background-color: $blue-2;  
        }
        .hyphen {
            background-color:$color-5; 
        }
        &:hover {
            &:before {
                background-color:$color-blue;  
            } 
            .hyphen {
                background-color:$color-5; 
            }
            .button_text {
                color: $color-5; 
            }
        }
    }
}

