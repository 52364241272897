@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.formCreate{
.fontSize{
    
    border-radius: 25px;
    border:1px solid $blue-3;
}
.flexName{
    display:flex;
    justify-content: space-between;
    
}
h4{
    color:$blue-3;
    text-transform: uppercase;
    font-weight: 400;
}
// label {
//     font-weight: bold;
// }
.textObli, .textStep2{
    font-style: italic;
    color:$grey-4;
    font-size: 12px;
    margin-top: 15px;
}
.textStep2{
    color:red;
    margin: 0;
}
.red{
    color:red;
}
.textConfir{
    text-decoration: underline;
    font-weight: bold;
    font-size: 12px;
    
}
.textConnexion{
    text-align: center;
    margin-top: 25px;
    font-size: 14px;
    a{
        text-decoration: underline;
        color:$blue-3;
    }

}

}
