@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.interset{
    border:2px solid $blue-3;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    
}
.interset label {
    cursor: pointer;
}



.interset input[type="checkbox"]{
     display: none;
    
}

.interset div label span, .getInterset span{ 
    position: relative;
    display: inline-block;
    color:$blue-3;
    border: 1px solid $blue-3;
    border-radius: 20px;
    padding: 5px 12px;
    margin:10px 15px;
    text-align: center;
    transition: 0.5s;
    user-select: none;
    overflow: hidden;
}
.interset .intersetDiv, .getInterset div{
    text-align: center;
}

.interset input[type="checkbox"]:checked ~ span{
   background-color: $blue-3;
   color: #fff;
}

.interset p{
    color: #0c3146;
    margin-left: 25px;
    font-style: italic;
    font-weight: bold;
}
.interset .buttonSubmit{
    background-color:#15acf2;
    border:none;
    width:150px;
    color:#fff;
    padding:10px;
    display: block;
   margin: 20px auto;
}
.getInterset .active{
    background-color: $blue-3;
   color: #fff;
}