
.pagination{
  
    .activePage a{
       background-color: #15acf2 ;
         color:#FFF;
    }
   
    a{
        color:#15acf2;
    }

}