@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";



.grid-2Blog {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    margin: 5px 0;
  
  
    @media (min-width:768px) and (max-width:998px) {
        grid-template-columns: 1fr;
        grid-gap: 65px;
    }
  
    @media (max-width:768px) {
      grid-template-columns: 1fr;
      grid-gap: 65px;
    }
}
.titleSpan{
    font-weight: bold;
    font-style: italic;

}

.buttonRead {
    display: inline-flex;
    align-items: center;
    color: rgb(6, 15, 30);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    height: 50px;
    min-width: 120px;
    position: relative;
    padding: 0px 20px;
    max-width: fit-content;
    &_text {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0px;
        z-index: 2;
        text-transform: uppercase;
    }

    &:disabled {
        opacity: 0.5;
    }

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 72px;
        background-color: rgb(252, 242, 43);
        border-radius: 40px;
        transition: all 0.3s ease-out 0s;
    }

    .hyphen {
        width: 20px;
        height: 2px;
        margin-right: 9px;
        background-color: rgb(6, 15, 30);
        transform-origin: 50% 50%;
        z-index: 1;
    }

    &:hover {
        color: rgb(255, 255, 255);
        &_text {
            color: rgb(255, 255, 255);
        }

        &:before {
            width: 100%;
        }

        .hyphen {
            animation: 1s linear 0s 1 normal none running kMPRhr;
        }
    }
}
.color{
    
    .button_text {
        color: #383838; 
    }
    &:before {
        background-color: $blue-2;  
    }
    .hyphen {
        background-color:$color-5; 
    }
    &:hover {
        &:before {
            background-color:$color-blue;  
        } 
        .hyphen {
            background-color:$color-5; 
        }
        .button_text {
            color: $color-5; 
        }
    }

}