
@import "../../Scss/_variables.scss";
@import "../../Scss/mixins.scss";

.technicalSupport{
    display: grid;
    grid-template-columns: 1fr;
    gap:10px;
    align-items: center;
    padding: 5px;
    width: 98%;
    margin:10px auto 20px;

    .toolbox{
        border:2px solid $blue-3;
        border-radius: 10px;
        padding: 10px ;
    }

    .pinfo{
        font-weight: bold;
        text-transform: uppercase;
        font-style: normal;
        width: 100%;
    }
    .content{
        flex-grow: 1; /* La zone centrale prend l'espace restant */
        margin-right: 20px;
    }   
    hr{
       height: 2px;
    }
    .modal {
        display: none;
      }
    .modalAdd{
        display: block;
    }
    .info{
        min-width: 70%;
    }
    .buttonExtended {
        display: inline-flex;
        align-items: center;
        color: rgb(6, 15, 30);
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0px;
        height: 50px;
        min-width: 100px;
        position: relative;
        padding: 0px 20px;
        max-width: fit-content;
        cursor: pointer;
        
        &_text {
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0px;
            z-index: 2;
            text-transform: uppercase;
        }
    
        &:disabled {
            opacity: 0.5;
            background-color: $color-5;
        }
    
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            width: 72px;
            background-color: $color-5;
            border-radius: 40px;
            transition: all 0.3s ease-out 0s;
        }
    
        .hyphen {
            width: 20px;
            height: 2px;
            margin-right: 9px;
            background-color: rgb(6, 15, 30);
            transform-origin: 50% 50%;
            z-index: 1;
        }
    
        &:hover {
            color: rgb(255, 255, 255);
            &_text {
                color: rgb(255, 255, 255);
            }
    
            &:before {
                width: 100%;
            }
    
            .hyphen {
                animation: 1s linear 0s 1 normal none running kMPRhr;
            }
        }
    }
    .ligne-texte-icone {
        display: flex;
        justify-content: space-between; /* Le texte à gauche et l'icône à droite */
        align-items: center; /* Aligne verticalement les éléments au centre */
        padding: 10px;
        background-color: transparent;
        border-radius: 8px;
        width: 100%;
    }
    .notif {
        font-size: 16px;
        font-weight: normal;
        color: $red;
        padding-left: 30px;
        text-transform: none;
        font-style: italic;
    }
    .texte {
        font-size: 18px;
    }
    .hidden {
        display: none; /* Cache l'icône */
    }
    .right {
        margin-left: 20px;
    }
    .color{
        .button_text {
            color: #383838; 
        }
        &:before {
            background-color: $blue-2;  
        }
        .hyphen {
            background-color:$color-5; 
        }
        &:hover {
            &:before {
                background-color:$color-blue;  
            } 
            .hyphen {
                background-color:$color-5; 
            }
            .button_text {
                color: $color-5; 
            }
        }
    }
}
// .technicalSupport{
//     display: grid;
//     grid-template-columns: 1fr;
//     gap:10px;
   
//     margin: 10px;
//      padding: 10px;

//     @media screen and (max-width:1100px) {
//         grid-template-columns:1fr;
        
//         }


//     .toolbox{
//         border:2px solid $blue-3;
//         border-radius: 10px;
//         padding: 10px ;
//     }
//     .flexCard{
//         display: flex;
//         flex-direction: column;
//         gap:30px;
//         align-items: center;
//         margin-top:10px;
//     }
//     .big-title_small {
//         font-size: 22px !important;
//     }
//     .hidden {
//         display: none; /* Cache l'icône */
//     }
//     .notif {
//         font-size: 16px;
//         font-weight: normal;
//         color: $red;
//         padding-left: 30px;
//     }

//     .flash {
//         font-size: 22px;
//         font-weight: bold;
//     }

//     .buttonExtended {
//         display: inline-flex;
//         align-items: right;
//         color: rgb(6, 15, 30);
//         font-size: 14px;
//         font-weight: 700;
//         letter-spacing: 0px;
//         height: 50px;
//         min-width: 100px;
//         position: relative;
//         padding: 0px 20px;
//         max-width: fit-content;
//         cursor: pointer;
        
//         &_text {
//             font-size: 14px;
//             font-weight: 700;
//             letter-spacing: 0px;
//             z-index: 2;
//             text-transform: uppercase;
//         }
    
//         &:disabled {
//             opacity: 0.5;
//             background-color: $color-5;
//         }
    
//         &:before {
//             content: "";
//             display: block;
//             position: absolute;
//             top: 0px;
//             left: 0px;
//             height: 100%;
//             width: 72px;
//             background-color: $color-5;
//             border-radius: 40px;
//             transition: all 0.3s ease-out 0s;
//         }
    
//         .hyphen {
//             width: 20px;
//             height: 2px;
//             margin-right: 9px;
//             background-color: rgb(6, 15, 30);
//             transform-origin: 50% 50%;
//             z-index: 1;
//         }
    
//         &:hover {
//             color: rgb(255, 255, 255);
//             &_text {
//                 color: rgb(255, 255, 255);
//             }
    
//             &:before {
//                 width: 100%;
//             }
    
//             .hyphen {
//                 animation: 1s linear 0s 1 normal none running kMPRhr;
//             }
//         }
//     }
//     .ligne-texte-icone {
//         display: flex;
//         justify-content: space-between; /* Le texte à gauche et l'icône à droite */
//         align-items: center; /* Aligne verticalement les éléments au centre */
//         padding: 10px;
//         background-color: transparent;
//         border-radius: 8px;
//         width: 100%;
//     }    
//     .texte {
//         font-size: 18px;
//     }
// }