@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.searchBarHeader{
.search{
    width: 50%;
   // margin:auto;
}
.searchInput{
    //margin-top:105px;
    display: flex;
}
.inputSearch{
    background-color: #fff;
    border:2px solid $blue-3;
    border-radius: 20px;
    // border-top-right-radius:0 ;
    // border-bottom-right-radius: 0;
    font-size: 16px;
    padding: 15px;
    height: 30px;
    width: 180px;
}

.searchIcon {
    width: 10px;
    height: 35px;
     background-color: transparent;
    display: grid;
    place-items: center;
    position: absolute;
     right: 25px;
    // top:28px;
    // width: 50px;
    // font-size: 40px;
    // height: 100%;

}
.searchIcon svg{
    font-size: 35px;
    color: $blue-3;

}

.inputSearch:focus{
    outline: none;
}
}