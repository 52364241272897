 @import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.sectionProfile{
     display: grid;
     grid-template-columns: repeat(2,1fr);
     gap:25px;
     margin-top: 25px;
    
  
   @media screen and (max-width:1100px) {
 // flex-direction: column;  
   grid-template-columns:1fr;
   }
}
