@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";
.password{
.btn-return{
    background-color: #DC3535;
    padding: 7px 50px;
    text-transform: "uppercase";
   width: 280px;
   border:none;
   color:#fff;
  
    
}
.btn-submit{
    background-color: $blue-3;
    color:#fff;
    display: block;
  width: 260px;
  border-radius: 0%;


}
.flexBtn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    @media screen and (max-width:768px) {
        flex-direction:column-reverse;
        
        
    }
}
}