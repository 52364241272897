@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

footer {
   
    background: rgba(11,49,69,0.2);
    padding: 50px;
    //margin-top: 2px;
    padding-bottom: 5px;
    border:3px solid #eee;
    margin-top:50px ;
   
    @media (max-width:768px) {
        text-align: center;
    }


    .logo {
        margin-bottom: 30px;
        display: block;
        width: 50%;

        @media (max-width:768px) {
            margin: 0 auto 30px;
        }
    }

    .contact {
        padding: 20px 0 20px;

        p {
            margin-bottom: 4px;
        }

        a {
            @include link($blue-4, $color-4, 18px);
        }

    }

    p {
        color: #000;
    }

    .grid {
        @include grid(4, 50px);

        @media (max-width:768px) {
            grid-template-columns: 1fr;
        }
    }

    .footer-link {
        display: block;

        a {
            display: block;
            color: #000;
            font-size: 18px;
            @include transition;

            &:hover {
                color: $color-4;
            }
        }
    }

    h4 {
        font-size: 18px;
        color: #0c3146;
        text-transform: uppercase;
        position: relative;
        margin-bottom: 20px;
        font-weight: bold;
        
    }
    .hover{
        &:hover{
            color: $blue-3;
        }
    }
 


    h5 {
        color: $blue-5;
        margin-bottom: 20px;
    }

    .social-icons {
        @media (max-width:768px) {
            justify-content: center;
        }
    }
 .buttonFed{
    border: none;
    background-color: transparent;
    margin-top: 5px;
 }
 .textArea{
    border: 1px solid #000;
    border-radius: 10px;
    
 }
 .formFed{
    display: flex;
    flex-direction: column;
    @media screen and (max-width:1100px) {
       
        align-items: center;
        
    }
 }
}