@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.confirm{
    display: block;
    background: #fff;
    width: 80%;
    //border:1px solid #000;
    // border-radius: 25px 25px 25px 25px;
    text-align: center;
    box-shadow: 10px 10px 10px 10px #eee;
    margin: 60px auto;
  
    padding-bottom: 50px;
    
    svg{
        font-size: 150px;
        margin: auto;
        color:$color-blue;
    }
    h1{
        font-size: 50px;
        font-weight: 300;
        
    }
   
    p{
        color:	#C0C0C0;
    }
}