@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.gridAccount{
    grid-template-columns: 1fr;
  }
  
  .progressCircle{
    // margin:20px 0;
    width: 200px;
     height: 200px;
  
  }
  .fa-circle-plus{
    color:$blue-3;
    font-size: 5rem;
    margin:15px;
    
   
  }
  .flexAdd{
    display: flex;
    align-items: center;
  }
  .linkActive{
    font-size: 25px;
    
    padding:5px 25px;
  }
  .user{
    display: flex;
    flex-direction: column;
    margin:50px 40px;
    a{
      text-decoration: underline;
      margin: 15px 0;
    }
  }
  .linkProgress{
   
   display: flex;
    flex-direction: column;
    align-items: center;
   
    a{
      text-decoration: underline;
      margin: 40px 0;
      
    }
    p{
      margin: 5px 0;
    }
    .fa-square{
      color:$color-blue;
    }
  }
  .blogCard,.FAQ{
    display: flex;
    flex-direction: column;
    gap:30px;
    border:2px solid $blue-3;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
   
  }
  .textWel{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px;
  }
  .name{
    font-size: 24px;
  }
  .welc{
    margin:20px;
  }
  .inputSize{
      
    border-radius: 10px;
    border:1px solid #000;
    margin: 0px;
    //padding: 10px;
    width: 100%;
    outline: none;
    height: 40px;
    
  }
  .inputSize::placeholder{
    font-style: italic;
  }
  .h2FAQ{
    color: $color-blue;
    font-weight: bold;
    font-size: 23.5px;
  }
  .buttonSubmit{
    position: absolute;
    top: 0;
    border-radius: 20px;
    right: 10px;
    z-index: 2;
    border: none;
    top: 8px;
    height: 25px;
    cursor: pointer;
    color: white;
    background-color: $blue-3;
    transform: translateX(2px);
  }
  .buttonIn {
    position: relative;
  }
  .info{
    width: 100%;
    border-bottom: 1px solid #000;
    font-style: normal;
    a{
      margin: 10px;
      width: 30%;
    }
    .i-action{
      text-align: center;
      width: 30%;
    }
  }
  