@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";


.formUser{
   
    .borderForm{
            border:2px solid $blue-3;
            border-radius: 10px;
            margin:10px;
            padding: 10px;

           
            
        
        }
        input{
            border:2px solid #eee;
            padding-left: 10px;
            //width: max-content;
            
            
            
        }
        label{
            text-transform: uppercase;
            padding-left: 5px;
            margin-right: 5px;
        }
        input:focus{
            outline: none;
        }
      .colorBtn{
        background-color:$blue-3;
        border: none;
        color:#fff;
        padding: 10px;
      }


     
}

