@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";
// // .navBarAccount{
//   header{
//     width: 100%;
//     height: 88px;
//     @media (max-width: 1200px) {
//       width: 80%;
//       height:80px;
     
//     }
//     @media (max-width: 768px) {
//       width: 60%;
    
//     height:65px;
    
//     }
//   }
// .NavbarItems{
// position: fixed;
// box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
// display: flex;
// background-color: #fff;
// justify-content: space-between;
// align-items: center;
// font-size: 1.2rem;
// padding:15px 30px;
// width: 100%;
//  height: 88px;
// flex-direction: row-reverse;
// flex-wrap: nowrap;
// z-index: 1;


// }

// .logoAcademy{
//    width: 50%;
//    margin:0;
//    padding: 0;
   

// }
// .NavMenu{
//     display: flex; 
//     align-items: center;
//     // justify-content: flex-end;
//     list-style: none;
//     li {

//         padding:10px 7px;
//    }
//    flex-flow: row nowrap;
//    margin-top:15px;
    
    

//     .a {
//         font-size: 18px;
//         color: #000;
//         padding:15px 5px;
//         margin-bottom: 30px;
//         text-transform: uppercase;
//         text-decoration: none;
//         @media screen and (max-width:1100px){
//           color:#fff;
          
//         }
//         &:hover {
//           color: #000;
         
         
//           @media(min-width:1100px){
//             background-color: $grey-3;
//             color:#fff;
//             transition: 350ms;
//           }
          
//         }
//     }
// }

// .right {
//   display: flex;
//   justify-content: space-around;
//   align-items: center;
  
// }

// .flex{
//   display:flex;
//   align-items:center;
//   flex-direction: row-reverse;
//   align-items: center;
//   //justify-content:space-between;
//   @media (max-width: 768px) {
//     flex-direction: row-reverse;
    
//   }           

// }

// //sous-menu
// @media (min-width: 1101px){

//   .dropdownMenu{
//     position: relative;
//     color: #000;
//     text-transform: uppercase;
    
//     @media screen and (max-width:1100px){
//       color:#fff;
      
//     }
//     &:hover {
//       color: #000;
//       @media(min-width:1100px){
//         padding:10px 5px;
//         background-color: $grey-3;
//         color:#fff;
//       }
      
//     }
    
//   }
//   .dropdownMenu-content {
//     display:none;
//     list-style:none;
//     position: absolute;
//     background-color: #f9f9f9;
//     min-width: 260px;
//     box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
//     padding: 12px 0px;
//     z-index: 1;
//     border-top:2px solid #15acf2; 
//     margin-top:9px;
//      li{
//       padding:20px 5px;
//       }
//     a{
//       padding: 10px 5px;
//     }  
//   }
  
  
//   .dropdownMenu > .spanDropown:after{
//     content: '❯';
//     font-size: 15px;
//     margin-left: 6px;
//     display: inline-block;

    
  
//   }
  
//   .dropdownMenu:hover .dropdownMenu-content {
//     display: block;
//   }
//   //animation flèche
//   .dropdownMenu:hover > .spanDropown:after{
//     animation: rotationFleche 0.2s linear forwards;
//   }
  
//   @keyframes rotationFleche {
//     0% {
//       transform: rotate(0deg);
//     }
//     50% {
//       transform: rotate(45deg);
//     }
//     100%{
//       transform: rotate(90deg);
//     }
//   }
//   }


.NavBar nav ul li{
 display:inline-block;
 // margin: 0 1.5vw;
}

.NavBar a {
  display: flex;
  align-items: center;
  padding:15px 8px;
  &:hover{
    background-color: $grey-3;
    color: #fff;
 
  }
}

.MobileNavigation{
  display:none;
}
