@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";
.elearningList{
    h2{
        padding-left: 50px;
        margin-top: 10px;
    }
    .elearningCard{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    gap:25px;
    // margin-top: 30px;
    margin: 40px 40px 40px;
   
    @media (max-width:768px) {
        grid-template-columns: 1fr;
    }
    @media screen and (min-width:768px) and (max-width:1100px) {
      grid-template-columns: 1fr 1fr;
  }
}
  .card:hover{
    border:2px solid $blue-5 !important;
    //box-shadow: -1px 9px 40px  -12px rgba(0,0,0,0.75);
    cursor: pointer;
    color:#000 !important;
}
.imgPicto{
    width:30px;
    height: 30px;
}
}

.elearningDetail{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .textProg{
        text-align: center;
        color: $color-blue;
        font-weight: 600;
        line-height: 3.6rem;
        margin: 30px;
    }
   .desc{
    width:75%;
   }
    p{
        color: $color-blue;
        font-weight: 600;
        padding:10px 40px;
       // margin:10px 15px;
        background-color: #EFEFF5;
       }
    ul{
        position:relative;
        background-color: #F6F7FB;
        padding: 0 ;
        margin:0
    }
   ul li{
        text-align: left;
        list-style: none;
        padding:10px;
        width:100%;
        border-bottom: 1px solid #fff; 
        box-shadow:  0 5px 25px #F6F7FB;
        transition: transform 0.5s; 
    }
   li:hover{
        cursor: pointer;
        background-color: #EFEFF5;
    }
    a{
        color: $blue-4;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
   button{
    background-color:$blue-5;
    border: 1px solid $blue-5;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    margin-left: auto;
    padding: 5px 11px;
    text-transform: uppercase;
   }
   
    .link{
        background-color:$blue-5;
        border: none;
        color:#fff;
        padding: 10px 25px;
        display: block;
        border-radius: 5px;
        font-weight: bold;
    }
   
   .fa-circle-check{
        margin:5px;
        color:$blue-5;
        font-size: 20px;
   }
}

.buttonNavigation{
    display: flex;
    align-items: center;
     justify-content: space-between;
    margin:15px;
.BtnElearning, .linkPre{
    background-color:$blue-3;
    border: none;
    color:#fff;
    padding: 15px 25px;
    display: block;
    border-radius: 10px;
    font-weight: bold;
  }
}
  .video{
    padding:50px;
  }


