@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.favorisSection{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap:10px;
   
    margin: 10px;
  padding: 10px;
  @media screen and (max-width:1100px) {
    grid-template-columns: 1fr;
    
    }

.favoris{

    border:2px solid $blue-3;
    border-radius: 10px;
    padding: 10px ;

}
.flexCard{
    display: flex;
    flex-direction: column;
    gap:30px;
    align-items: center;
    margin-top:10px;
}
.big-title_small {
    font-size: 22px !important;
    
}
}