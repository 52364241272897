@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";




.sectionInterest{
    .addinter{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .submitButton{
        background-color:#15acf2;
        border:none;
        width:150px;
        color:#fff;
        padding:10px;
        display: block;
       margin: 20px auto;
    }
    .span label{
        color:$blue-3;
        border: 1px solid $blue-3;
        border-radius: 15px;
        padding: 10px 15px;
        margin:10px;
        text-align: center;
    }
    
    .span{
        margin:30px 40px;
    }
   .formInter p{
        color: #0c3146;
        margin-left: 25px;
        font-style: italic;
        font-weight: bold;
    }
}
