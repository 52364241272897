@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.displayVersion {
    max-width: 900px;  // Limiter la largeur pour une seule colonne
    margin: 0 auto;    // Centrer la colonne
    border: 2px solid $blue-3;
    border-radius: 10px;
    padding: 12px;
    // gap:25px;
    margin-top: 25px;
}

.form-group {
    display: flex;
    align-items: center; // Aligner verticalement label et input
    margin-bottom: 1.5rem;
    gap: 1rem; // Espacement entre label et input
}

.form-group label {
    flex: 1; // Le label prendra 1 part de la largeur totale
    max-width: 150px; // Limiter la largeur du label
}

.form-group input, 
.form-group textarea {
    flex: 2; // L'input prendra 2 parts de la largeur totale
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%; // S'assurer que l'input occupe tout l'espace
    max-width: 100%;
}

textarea {
    resize: vertical;
    height: 100px; // Hauteur du textarea pour la description
}

.form-group.textarea-group {
    flex-direction: column; // Pour que la description soit sur une nouvelle ligne
    align-items: flex-start; // Aligner à gauche
}

textarea {
    width: 100%; // S'assurer que le textarea prenne toute la largeur
}

.error {
    color: $red;
    font-size: 0.9rem;
    margin-top: 0.5rem;
}

.btn {
    padding: 0.8rem 1.2rem;
    background-color: $blue-3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.btn:disabled {
    background-color: #cccccc;
}

.form-group.d-flex.justify-content-end {
    justify-content: flex-end;
}

// .displayVersion{
//     display: grid;
//     grid-template-columns: repeat(2,1fr);
//     justify-content: center;
//     border: 2px solid $blue-3;
//     border-radius: 10px;
//     gap:25px;
//     padding: 10px;
//     margin-top: 25px;
//     width: 98%;

//     @media screen and (max-width:1100px) {
//         grid-template-columns:1fr;
//     }

//     img{
//         width: 200px;
//         @media screen and (max-width:1100px) {
//             //flex-direction: column;   
//             float: none;
//             margin: auto;
//             display: block;
//         }
//     }
//     .fa-trash-can{
//        text-align: right;
//     }
//     .pinfo{
//         font-weight: bold;
//         text-transform: uppercase;
//         font-style: italic;
//         @media screen and (max-width:1100px) {
//             text-align: center;
            
//     }   
//     }
//     .info{
//         width: 65%;
//         margin-left: 30px;
//         @media screen and (max-width:1100px) {
//             width: 80%;
//         }
//     }   
//     hr{
//        height: 2px;
//     }
//     .modal {
//         display: none;
//       }
//     .modalAdd{
//         display: block;
//     }
//     .borderForm{
//         border:2px solid $blue-3;
//         border-radius: 10px;
//         margin:10px;
//         padding: 10px;
//         min-width: 70%;
       
        
    
//     }
//     input{
//         border:2px solid #eee;
//         padding-left: 10px;
//         //width: max-content;
        
        
        
//     }
//     label{
//         text-transform: uppercase;
//         padding-left: 5px;
//         margin-right: 5px;
//     }
//     input:focus{
//         outline: none;
//     }
//     .colorBtn{
//         background-color:$blue-3;
//         border: none;
//         color:#fff;
//         padding: 10px;
//     }
// }