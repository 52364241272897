@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";



.containerFlex{
    display:flex;  
}
main{
 width:100%;
 //padding-left: 10px;
 
}

.sidebar{
  color: #000;
  height: auto;
  width: 300px;
  transition: all 0.5s;
  border: 3px solid #eee;
  border-bottom: none; 
 
}


.logo{
  text-align: center;
  font-size: 2.2rem;  
  height: auto;
}
.bars{
  text-align: right;
  font-size: 2.2rem;  
  height: auto;
  margin-right: 10px;
 
 
}
.link{
  display: flex;
  color: #000;
  padding: 17px 25px;
  gap: 25px;
  align-items: center;
  transition: all 0.5s;
}
.link:hover{
  cursor: pointer;
  background-color:$grey-3;
  color:#fff;
  transition: all 0.5s;
}
.link_text{
  display: block;
  @media screen and (max-width:768px) {
    display: none;
   }
}
.show{
  color: #000;
}

.imgpro{
  width: 30px;
  padding: 0;
  margin:0;
 
}