@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";




.closeButton {
  border-radius: 8px;
  color:#fff;
  background-color: #15acf2;
  padding: 5px ;
  
}
.gridCard  {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr ;
  gap:25px;
  // margin-top: 30px;
  margin: 40px;
   
    @media (max-width:768px) {
        grid-template-columns: 1fr;
    }
    @media screen and (min-width:768px) and (max-width:1100px) {
      grid-template-columns: 1fr 1fr;
  }
 }

.select {
  font-size: 1rem;
  padding: 2px 5px;
  margin: 30px 20px;
  width: 250px;
  border-radius: 8px;
  @media (max-width:768px) {
    margin:15px 10px;
}
}
.filter{
  display: flex;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  padding: 15px 0px;
  @media (max-width:768px) {
    flex-direction: column;
}
  label{
    font-weight: bold;
  }
}