@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

// input[type=password], input[type=email] {
//     width:100%; 
//     margin:8px 0; outline:none; 
//     padding:8px; 
//     box-sizing:border-box; 
// }
// input[type=text], input[type=email]{
//      height:35px 
// }
.activeInput{
    border:2px solid $blue-3;
    border-radius: 10px;
}
.input-icone{
    position:relative; 
}
.input-icone i{
    position:absolute; 
    left:0;
    top:8px;
    font-size:20px;
    padding-left: 8px;
     
}
.colorLink{
    color:#000;
    text-transform: uppercase;
    display: block;
    background-color:$color-grey ;
    padding: 10px;
    margin-bottom: 10px;
}
// .fa-lock{
//     font-size:25px;
//     position:absolute; 
//     top:0;
// }
.error{
    color:red;
}
.link{
    text-transform: none;
    padding: 10px 50px;
}
.colorButton{
    background-color:$blue-3;
    color:#fff;
    padding: 7px 5px;
    width: 40%;
    border-radius: 0%;
    @media screen and (max-width:768px) {
        width: 45%;
        
    }
}