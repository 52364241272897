$color-1: #05151E;
$color-2: #6496A9;
$color-3: #55ACDF;
$color-4: #6DFFF2;
$color-5: #FFFFFF;
$color-grey: #bebebe;
$color-blue: #0c3146;
$color-dark: #292929;
$button_grey: #6a6a6a;

$blue-1: #55acdf;
$blue-2: #a6c0d0;
$blue-3: #15acf2;
$blue-4: #090a0a;
$blue-5: #3d5a6b;
$grey-1: #383838;
$grey-2: #e4e4e4;
$grey-3: #d7d7d7;
$grey-4: #3e3e3e;
$grey-5: #e3e3e3;
$grey-6: #e4e3e4;

$red: #c94343;

.color-blue {
    color: $color-blue;
    font-weight: 500;
}
$HeightHeader:85px;
@media (max-width: 1200px){
    $HeightHeader:80px;
}
@media (max-width:768px){
    $HeightHeader:65px;
}
