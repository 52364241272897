@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";
.homePage{
.flexHome{
    display:flex;
    justify-content: space-between;
    align-items: center;
   
    background-color:rgba(11,49,69,0.2);
    @media screen and (max-width:1100px) {
        flex-direction: column;
        
    }

    img{
        display: block;
        margin: auto;
        width: 250px;
        padding: 15px 15px;
        background-color: transparent;
        margin-bottom:50px;
    }
}

.pHome,.pPlatforme{
    text-align:center;
    font-size:1.2rem;
    color:$blue-4;
    font-weight:bold;
    color:$color-blue;
    text-transform: uppercase;
   
    
    
}
.pPlatforme{
    font-style: italic;
    text-transform: lowercase;
}
.pPlatforme::first-letter{
    text-transform: uppercase;
}
.linkButton{
    width: 250px;
    background-color: $blue-3;
    text-align: center;
    padding: 10px;
    color: #fff;
    font-weight: bold;
    display: block;
    margin:auto;
    font-size: 16px;
    

}



}