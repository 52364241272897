@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.bradcrumb {
    text-align: center;
    padding: 12px  15px;
    background: linear-gradient(145deg, #e6e6e6, #ffffff);
   
    
    h1 {
       text-align: center;
    padding: 10px 15px;
       font-weight: bold;
        color: $blue-1;
        //margin-bottom: 20px;
        text-transform: uppercase;
        @media (max-width:500px) {
            max-width: 300px;
            margin: 0 auto;
           
            font-size: 30px !important;
        }
    }
    h2 {
        color: $color-blue;
        @media (max-width:768px) {
            font-size: 19px;
            padding-top:15px;
        }
       
    }
}