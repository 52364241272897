@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.big-title {
    margin-top: 30px;

    @media (max-width:998px) {
        margin-top: 20px;
    }

    .big_text {
        font-size: 150px;
        color: $grey-1;
        font-weight: 900;
        opacity: 0.05;
        line-height: 100px;
        position: absolute;
        top: -80px;

        text-transform: uppercase;
    }

    h2 {
        max-width: 100%;
        color: $color-blue;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 23.5px;
        padding: 0 80px;


        &:before {
            content: "";
            position: absolute;
            left: -25px;
            width: 80px;

            height: 1px;
            top: 50%;
            background: $blue-3;
            transform: translateY(-50%);
            z-index: 1;
        }

        &:after {
            position: absolute;

            left: 50px;
            width: 10px;
            height: 10px;
            content: "";
            background: $color-blue;
            border-radius: 50%;
            top: 50%;
            margin-top: -5px;
            transform: translateY(-50%);
            bottom: -30px;
            transform: none;
            z-index: 1;
        }

        span {
            color: $color-blue;
            text-transform: uppercase;

        }
    }

    .left {
        position: relative;

        .big_text {
            left: -5vw;

            @media (max-width:768px) {
                line-height: normal;
            }
        }
    }

    .right {
        position: relative;

        .big_text {
            right: -5vw;

            @media (max-width:768px) {
                line-height: normal;
            }
        }

        h2 {
            text-align: center;
            width: fit-content;
            margin: 0 auto;
            padding: 0 50px;

            @media (max-width:768px) {
                padding: 0 !important;
            }

            &:before {
                content: "";
                position: absolute;
                left: auto;
                right: -90%;
                width: 90%;
                height: 1px;

                top: 50%;
                background: $blue-3;
                transform: translateY(-50%);

                @media (max-width:768px) {

                    right: -64px !important;
                    width: 150px !important;
                    top: auto !important;
                    bottom: -25px !important;
                    transform: none;

                    left: auto;
                }
            }

            &:after {
                position: absolute;
                right: 0;
                left: auto;
                width: 10px;
                height: 10px;
                content: "";
                background: $color-blue;
                border-radius: 50%;
                top: 50%;
                margin-top: -5px;
                transform: translateY(-50%);
                bottom: -30px;
                transform: none;

                @media (max-width:768px) {
                    top: auto;
                    bottom: -30px;
                    transform: none;
                    right: 76px;
                }
            }
        }
    }

    @media (max-width:769px) {

        .big_text {
            font-size: 40px;
            top: -50px;
            left: 50% !important;
            transform: translateX(-50%);
            max-width: 500px;
            width: 100%;
            text-align: center;
        }

        span {
            display: block;
        }




        // h2 {

        //     text-align: center;

        //     &:before {
        //         left: -64px;
        //         width: 150px;
        //         top: auto;
        //         bottom: -25px;
        //         transform: none;
        //     }

        //     &:after {
        //         position: absolute;
        //         left: 76px;
        //         width: 10px;
        //         height: 10px;
        //         content: "";
        //         background: $color-blue;
        //         border-radius: 50%;
        //         top: auto;
        //         bottom: -30px;
        //         transform: none;
        //     }
        // }
    }

    @media (min-width:768px) and (max-width: 998px) {
        .big_text {
            font-size: 60px;
        }

    }

    &_small {
        font-size: 28px;
        color: $blue-3 !important;
        display: block;
        max-width: 700px;

        @media (max-width:768px) {
            font-size: 18px;
        }
    }
}