@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.formDevice{

    input{
        border:2px solid #eee;
        padding-left: 10px;
       
        
    }
    input:focus{
        outline: none;
    }
    .span, .textOblig{
        font-weight: 400;
        font-style: italic;
        padding-left: 4px;
    }
    .textOblig{
        font-size: 10px;
    }
    .ButtonFile{
        background-color: #eee;
        color:$blue-3;
        width: 280px;
        padding: 15px 10px;
        text-align: center;
    }
    select option {
        display: block;
        font-size: 1.2rem;
    }
    
    select {
        padding: .7rem 1.5rem;
        font-size: 1.2rem;
    }

    #file-chosen{
        margin-left: 0.3rem;
        font-weight: 400;
       
        
      }
      .font{
        font-weight: 400;
      }
      svg {
        font-size: 100px;
        color:$color-blue;
        text-align: center;
        margin-left: 400px;
      }
      .deviceCenter{
        text-align: center;
      }
}