@import "../../Scss/_variables.scss";
@import "../../Scss/_mixins.scss";

.MobileNavigation{
    display: flex;
    justify-content: start;
    align-items: center;
}

.menu-bars{
    margin-left:0.5rem;
    font-size: 2rem;
    background: none;

}
.navUl{
    margin-top: 10px;
}
.openMenu{
    display: block;
}
.closeMenu{
    display: none;
}


.nav-menu{
    width: 300px;
    height:100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top:80px;
    left:-100%;
    transition:850ms;
    background-color: $blue-4;
    z-index: 1;
    padding-top:15px;
    
}
.nav-menu.active{
    left:0;
    transition: 350ms;
}
.ulMobile{
    list-style: none;
}
.nav-text{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 8px;
    list-style: none;
    color:#fff;

}
.nav-text a {
    color: #fff;
    font-size: 18px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 15px 8px;


}
.nav-text a:hover{
    background-color: $grey-3;
}